//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Img from "gatsby-image";

const Post = ({ data }) => {
	//console.log(data);
	const post = data.markdownRemark;
	return (
		<Layout>
			<SEO
				keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
				title={post.frontmatter.title}
			/>
			<div className="bg-white">
				<Img
					className="w-full object-cover h-64"
					fluid={post.frontmatter.image.childImageSharp.fluid}
					alt=""
				/>

				<div className="border-t-2 border-gray-100 pt-10 max-w-xl px-4 mx-auto mb-10">
					<h2 className="text-3xl leading-9 font-extrabold text-gray-900 max-w-3xl mx-auto mb-8">
						{post.frontmatter.title}
					</h2>
					{/*
					<p className="text-sm leading-5 font-medium text-gray-900">
						LP Tree Service
					</p>
					<p className="text-sm leading-5 text-gray-500">Janesville, WI</p>
					<div className="flex text-sm leading-5 text-gray-500">
						<time dateTime="2020-03-16">{post.frontmatter.date}</time>
						<span className="mx-1">·</span>
					</div>
					*/}
					<div
						className="wysiwyg "
						dangerouslySetInnerHTML={{ __html: post.html }}
					></div>
				</div>
			</div>
		</Layout>
	);
};

export default Post;

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				date
				image {
					childImageSharp {
						fluid(maxWidth: 2000) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
